<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12 d-flex">
        <el-pagination
          class="mx-auto mb-3"
          :page-size="9"
          :pager-count="11"
          layout="prev, pager, next"
          background
          :total="total"
          :hide-on-single-page="true"
          prev-text="上一页"
          next-text="下一页"
          v-model="currentPage4"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { ElPagination } from 'element-plus'
const props = defineProps({
  currentPage: { type: Number },
  total: { type: Number, default: 0 }
})
const emit = defineEmits(['update:currentPage'])
const currentPage4 = computed(() => props.currentPage)
function handleCurrentChange(val) {
  console.log('val', val)
  emit('update:currentPage', val)
}
</script>
<style lang="stylus" scoped></style>
